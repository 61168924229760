<template>
  <div class="container">
    <BaseHeader title="Lista de Leads"> </BaseHeader>

    <div class="container-table">
      <!-- Filtros -->
      <div class="header-table">
        <div class="flex-sale pr-3">
          <div class="d-flex align-items-center">
            <b-tooltip
              target="filtro-1"
              title="Aplicar Filtro"
              placement="topright"
            />
            <b-button
              id="filtro-1"
              class="btn-table"
              @click="openFilters"
              title="Filtros"
              ><img src="@/assets/img/icons/filtro.svg"
            /></b-button>

            <b-tooltip
              target="exportar-1"
              title="Exportar Dados"
              placement="topright"
            />
            <b-button
              @click="exportData"
              class="btn-table"
              title="Exportar dados"
              id="exportar-1"
              ><img src="@/assets/img/icons/download.svg" alt=""
            /></b-button>
          </div>

          <div class="d-flex align-items-center flex-acoes">
            <div class="datas">
              <date-range-picker
                ref="picker"
                opens="right"
                :locale-data="localeDate"
                :showWeekNumbers="false"
                :showDropdowns="true"
                :autoApply="false"
                :ranges="dateRanges"
                @update="fetchLeads"
                v-model="dateRange"
              >
                <template
                  v-if="dateRange.startDate && dateRange.endDate"
                  v-slot:input="picker"
                  style="min-width: 350px"
                >
                  {{ picker.startDate | date }} - {{ picker.endDate | date }}
                </template>
                <template
                  v-else
                  v-slot:input
                  class="btn-table"
                  title="Selecionar datas"
                >
                  Selecione as datas
                </template>
              </date-range-picker>
            </div>
            <div class="pl-3">
              <b-form-input
                type="text"
                placeholder="Pesquise pelo lead"
                v-on:input="debounce"
                v-model="search"
                style="width: 300px; max-height: 60px"
              />
            </div>
          </div>
        </div>

        <b-col cols="12" md="3" sm="12" class="pr-3 pt-2 container-pesquisa">
          <b-form-select
            :options="[
              { value: null, text: 'Todos os leads' },
              { value: 'paid', text: 'Pago' },
              { value: 'abandoned', text: 'Abandonado' },
              { value: 'waiting_payment', text: 'Aguardando Pagamento' },
              { value: 'canceled', text: 'Cancelado' },
              { value: 'trialing', text: 'Trial' },
              { value: 'in_negociation', text: 'Em Negociação' },
            ]"
            v-on:input="debounce"
            size="sm"
            v-model="status"
          >
          </b-form-select>
        </b-col>

        <b-col cols="12" md="3" sm="12" class="pt-2 container-pesquisa">
          <b-form-select
            text="Todas as negociações"
            :options="[
              { value: null, text: 'Todos as negociações' },
              { value: 'opened', text: 'Em aberto' },
              { value: 'in_negociation', text: 'Em Negociação' },
              { value: 'objection', text: 'Objeção' },
              { value: 'lost_trading', text: 'Negociação perdida' },
              { value: 'win', text: 'Vencemos' },
            ]"
            v-on:input="debounce"
            size="sm"
            v-model="negociation_status"
          >
          </b-form-select>
        </b-col>
      </div>

      <!-- HEADER -->
      <b-row class="Table-header mb-1">
        <b-col cols="1" class="d-none d-md-block"> #ID </b-col>

        <b-col cols="5" md="2" class="d-md-block mr-3">
          Dados de Contato
        </b-col>

        <b-col cols="4" md="2" class="d-md-block"> Produto </b-col>

        <b-col cols="1" class="d-none d-md-block mr-1"> Venda</b-col>

        <b-col cols="1" class="d-none d-md-block mr-4"> Afiliados</b-col>

        <b-col cols="1" class="d-none d-md-block mr-5"> Última ação </b-col>

        <b-col cols="2" class="d-none d-md-block"> Status</b-col>

        <b-col cols="2" md="1" class=""> Ações </b-col>
      </b-row>
      <!-- END HEADER -->

      <!-- Body -->
      <template v-if="!loading && leads.length > 0">
        <b-row class="Table-body" v-for="(item, index) in leads" :key="item.id">
          <!-- ID -->
          <b-col
            cols="1"
            class="d-none d-md-block"
            v-b-tooltip="{
              title: getStatus(item.status).text,
              placement: 'top',
            }"
          >
            <div style="display: flex; align-items: center">
              <div :class="getStatus(item.status).class"></div>
              <span>
                {{ item.id }}
              </span>
            </div>
          </b-col>

          <!-- Dados de contato -->
          <b-col class="d-md-block dados-cliente mr-3" cols="5" md="2">
            <div>
              {{ item.name }}
            </div>

            <div
              :id="item.email"
              v-b-tooltip="{
                title: item.email,
                placement: 'top',
              }"
            >
              {{
                item.email && item.email.length > maxLength
                  ? item.email.substr(0, maxLength) + "..."
                  : item.email
              }}
            </div>
            <WhatsTel :tel="item.cellphone" :index="index" />
          </b-col>

          <!-- Produtos -->
          <b-col cols="4" md="2" class="d-md-block dados-cliente">
            <div
              v-b-tooltip="{
                title: item.product.name,
                placement: 'top',
              }"
            >
              {{
                item.product.name && item.product.name.length > maxLength
                  ? item.product.name.substr(0, maxLength) + "..."
                  : item.product.name
              }}
            </div>

            <div :id="item.product.type">
              {{
                item.product.type == "TRANSACTION"
                  ? "PREÇO ÚNICO"
                  : "ASSINATURA"
              }}
            </div>

            <div :id="item.product.amount" v-if="item.product.amount">
              R$ {{ formatMoney(item.product.amount) }}
            </div>
          </b-col>

          <!-- Detalhes da Venda -->

          <b-col cols="1" class="d-none d-md-block dados-cliente mr-1">
            <div v-if="item.sale && item.sale.method">
              <span class="flex">
                <font-awesome-icon
                  v-if="item.sale.method != 'TWO_CREDIT_CARDS'"
                  :icon="getIconMethod(item.sale.method)"
                  class="icon-gray"
                  :id="`method-icon-${item.sale.id}`"
                />
                <b-tooltip
                  :target="`method-icon-${item.sale.id}`"
                  :title="getMethod(item.sale.method)"
                  placement="left"
                />
                <font-awesome-icon
                  v-if="item.sale.method == 'TWO_CREDIT_CARDS'"
                  icon="credit-card"
                  class="icon-gray"
                  :id="`method-icon-${item.sale.id}`"
                />
                <font-awesome-icon
                  v-if="item.sale.method == 'TWO_CREDIT_CARDS'"
                  icon="credit-card"
                  class="icon-gray TWO_CREDIT_CARDS"
                />

                <font-awesome-icon
                  v-if="item.sale.bump_id"
                  icon="donate"
                  class="icon-gray ml-2"
                  :id="`bump-sale-${item.sale.id}`"
                />

                <b-tooltip
                  v-if="item.sale.bump_id"
                  :target="`bump-sale-${item.sale.id}`"
                  title="ORDER BUMP"
                  placement="left"
                />

                <font-awesome-icon
                  v-if="item.sale.coupon_id"
                  icon="receipt"
                  class="icon-gray ml-2"
                  :id="`cupom-sale-${item.sale.id}`"
                />
                <b-tooltip
                  v-if="item.sale.coupon_id"
                  :target="`cupom-sale-${item.sale.id}`"
                  title="CUPOM DE DESCONTO"
                  placement="left"
                />
              </span>
            </div>
            <div v-else>-</div>

            <div v-if="item.sale && item.sale.installments">
              Parcelas: {{ item.sale.installments }}x
            </div>
            <div v-else>-</div>

            <div v-if="item.sale && item.sale.amount">
              R$ {{ formatMoney(item.sale.amount) }}
            </div>
            <div v-else>-</div>
          </b-col>

          <!-- Afiliado -->
          <b-col class="d-none d-md-block mr-4" cols="1">
            <span v-if="item.affiliate && item.affiliate.name">{{
              item.affiliate.name
            }}</span>
            <span>-</span>
          </b-col>

          <!-- Visto por Último -->

          <b-col
            class="d-none d-md-block mr-5"
            v-b-tooltip="{
              title: !!item.last_seen
                ? moment(item.last_seen).format('DD/MM/YYYY HH:mm')
                : '',
              placement: 'top',
            }"
            cols="1"
          >
            {{
              !!item.last_seen
                ? moment(item.last_seen)
                    .locale("pt-br")
                    .fromNow()
                : "-"
            }}
          </b-col>

          <!-- Status da negociação -->
          <b-col cols="2" class="pr-5 d-none d-md-block">
            <GSelect
              :uid="`${item.id}`"
              text="Status da negociação"
              @change="updateLead($event, item)"
              v-model="item.negociation_status"
              :options="[
                { value: 'opened', text: 'Em aberto' },
                { value: 'in_negociation', text: 'Em Negociação' },
                { value: 'objection', text: 'Objeção' },
                { value: 'lost_trading', text: 'Negociação perdida' },
                { value: 'win', text: 'Vencemos' },
              ]"
            />
          </b-col>

          <!-- Ações -->
          <b-col cols="2" md="1">
            <div style="display: flex">
              <b-tooltip
                :target="`link-checkout-${item.id}`"
                title="Copiar Link do Checkout"
                placement="top"
              />
              <div
                :id="`link-checkout-${item.id}`"
                @click="copyAddress(`${baseURL}/${item.product.id}`)"
              >
                <img
                  class="pointer"
                  width="16"
                  style="opacity: 0.7; margin-right: 24px"
                  src="@/assets/img/icons/copy.svg"
                />
              </div>
              <div @click="openPanel(item)">
                <img class="pointer" src="@/assets/img/icons/eye.svg" />
              </div>
            </div>
          </b-col>
        </b-row>
      </template>

      <!-- Paginate -->
      <b-row>
        <b-col>
          <Paginate
            v-if="leads.length > 0 && !initExport"
            :totalPages="pagination.totalPages"
            :activePage="pagination.currentPage"
            @to-page="toPage"
            @per-page="perPage"
          />
        </b-col>
      </b-row>

      <!-- Loading -->
      <div
        v-if="(loading && !leads.length) || (loading && initExport)"
        class="Table-body py-4 d-flex justify-content-center align-items-center"
      >
        <b-spinner variant="dark" label="Spinning"></b-spinner>
      </div>

      <b-row
        v-if="!loading && !leads.length"
        class="Table-body justify-content-center"
      >
        <p class="nao-encontrado">Nenhum lead foi encontrado</p>
      </b-row>

      <!-- END BODY -->

      <!-- modals -->
      <FiltersModal @filter="filter" />
      <LeadPreview @exportDataLead="exportData" ref="leadPreview" />
    </div>
    <!--<CallEvaluation /> -->
  </div>
</template>
<script>
import LeadService from "@/services/resources/LeadService";
import Paginate from "@/components/shared/Paginate";
import FiltersModal from "@/components/Seller/Leads/modals/filters";
import DateRangePicker from "vue2-daterange-picker";
import Money from "@/mixins/money";
import moment from "moment-timezone";
import LeadPreview from "@/components/shared/SalePreview";
import GSelect from "@/components/shared/GSelect";
import WhatsTel from "@/components/shared/WhatsTel";
import _ from "lodash";
import { iconMethod } from "@/helpers.js";
import { textMethod } from "@/helpers.js";

const service = LeadService.build();

export default {
  name: "ListaDeLeads",
  components: {
    Paginate,
    FiltersModal,
    DateRangePicker,
    LeadPreview,
    GSelect,
    WhatsTel,
  },
  data() {
    return {
      moment: moment,
      initExport: false,
      leads: [],
      sale_id: -1,
      search: null,
      lead_status: null,
      loading: true,
      total: 0,
      itens: 0,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10,
      },
      filters: {
        status: null,
        negociation_status: null,
      },
      type_date: "created_at",
      types_list: [
        { value: "created_at", text: "Data de criação" },
        { value: "paid_at", text: "Data de pagamento" },
      ],
      localeDate: {
        direction: "ltr",
        format: "dd/mm/yyyy",
        separator: " - ",
        applyLabel: "Aplicar",
        cancelLabel: "Cancelar",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
        monthNames: [
          "Jan",
          "Fev",
          "Mar",
          "Abr",
          "Mai",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Nov",
          "Dez",
        ],
        firstDay: 0,
      },
      dateRange: {
        startDate: moment(),
        endDate: moment(),
      },
      columns: [
        {
          key: "id",
          label: "Fatura",
        },
        {
          key: "product",
          label: "Produto",
        },
        {
          key: "client",
          label: "Visto por Último",
        },
        {
          key: "created_at",
          label: "Status da Negociação",
        },
        {
          key: "paid_at",
          label: "Ações",
        },
      ],

      client: {
        width: 0,
      },
      status: null,
      negociation_status: null,
      toogleTel: [],
    };
  },
  mixins: [Money],
  computed: {
    baseURL() {
      return process.env.VUE_SALES_PAGE;
    },
    active() {
      return this.$route.name;
    },
    isSeller() {
      return this.$store.getters.isSeller;
    },
    userID() {
      return this.$store.getters.getProfile.id;
    },
    maxLength() {
      return this.$store.getters["sidebarState"] ? 20 : 27;
    },
    dateRanges() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      let lastWeek = new Date();
      lastWeek.setDate(today.getDate() - 7);
      lastWeek.setHours(0, 0, 0, 0);

      return {
        Hoje: [today, today],
        Ontem: [yesterday, yesterday],
        "Última semana": [lastWeek, today],
        "Este mês": [
          new Date(today.getFullYear(), today.getMonth(), 1),
          new Date(today.getFullYear(), today.getMonth(), 30),
        ],
        "Este ano": [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31),
        ],
        "Último mês": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        "Todo período": [new Date(2020, 0, 1), new Date(today)],
      };
    },
  },

  methods: {
    getIconMethod(method) {
      return iconMethod(method);
    },
    getMethod(method) {
      return textMethod(method);
    },
    changeToggle(index) {
      console.log("aqui", index, this.toogleTel[index]);
      if (this.toogleTel[index] == true) {
        this.toogleTel[index] = false;
      } else {
        this.toogleTel[index] = true;
      }
    },
    maskTel(v) {
      let r = v.replace(/\D/g, "");
      r = r.replace(/^0/, "");

      if (r.length > 11) {
        r = r.replace(/^(\d\d)(\d{2})(\d{4,5})(\d{4}).*/, "$1 ($2) $3-$4");
      } else if (r.length > 7) {
        r = r.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, "($1) $2-$3");
      } else if (r.length > 2) {
        r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
      } else if (v.trim() !== "") {
        r = r.replace(/^(\d*)/, "($1");
      }
      return r;
    },

    getDDD(number) {
      return parseInt(number.match(/(?:\d{2})(\d{2})/)[1]);
    },
    removeNineDigit(number) {
      if (!number) return number;
      const replaceRegex = new RegExp("(\\d{4})(9)(\\d*)");
      return number.replace(replaceRegex, "$1$3");
    },
    addNineDigit(number) {
      const replaceRegex = new RegExp("(\\d{4})(\\d*)");
      return number.replace(replaceRegex, "$19$2");
    },
    isBrazilian(number) {
      const brasilianRegex = new RegExp("^55");
      return brasilianRegex.test(number);
    },
    getRawNumber(number) {
      const numRegex = new RegExp("(\\D)", "g");
      return `${number}`.replace(numRegex, "");
    },
    getSecondLink(number) {
      try {
        let parsedNum = this.parseNum(number);
        if (!this.isBrazilian(parsedNum)) {
          return false;
        } else {
          if (this.getDDD(parsedNum) > 30) {
            return `https://api.whatsapp.com/send?phone=+${parsedNum}`;
          } else {
            return false;
          }
        }
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    parseNum(number) {
      try {
        let rawNumber = this.getRawNumber(number);
        if (this.isBrazilian(rawNumber)) {
          if (this.getDDD(rawNumber) > 30) {
            if (rawNumber.length > 12) {
              rawNumber = this.removeNineDigit(rawNumber);
            }
          } else {
            if (rawNumber.length < 13) {
              rawNumber = this.addNineDigit(rawNumber);
            }
          }
        }
        return rawNumber;
      } catch (error) {
        console.error(error);
        return number;
      }
    },
    formatTel(tel) {
      let parsedNum = this.parseNum(tel);
      if (parsedNum) {
        return `https://api.whatsapp.com/send?phone=${parsedNum}`;
      }
    },
    iconMethod(method) {
      let icon = "";
      switch (method) {
        case "CREDIT_CARD": {
          icon = "credit-card";
          break;
        }
        case "BOLETO": {
          icon = "barcode";
          break;
        }
        case "PIX": {
          icon = "qrcode";
          break;
        }
        case "PAYPAL": {
          icon = ["fab", "paypal"];
          break;
        }
        default: {
          icon = "coins";
          break;
        }
      }
      return icon;
    },
    getStatus(status) {
      let r = {};
      if (status) {
        switch (status.toLowerCase()) {
          case "opened": {
            r = { text: "Aberto", class: "status status--opened" };
            break;
          }
          case "in_negociation": {
            r = {
              text: "Em negociação",
              class: "status status--in_negociation",
            };
            break;
          }
          case "objection": {
            r = {
              text: "Objeção",
              class: "status status--objection",
            };
            break;
          }
          case "lost_trading": {
            r = {
              text: "Negociação Perdida",
              class: "status status--lost_trading",
            };
            break;
          }
          case "win": {
            r = {
              text: "Vencemos",
              class: "status status--win",
            };
            break;
          }
          case "": {
            r = { text: "list", class: "is-red" };
            break;
          }

          case "waiting_payment": {
            r = {
              text: "Aguardando pagamento",
              class: "status status--opened",
            };
            break;
          }
          case "paid": {
            r = { text: "Pago", class: "status status--in_negociation" };
            break;
          }
          case "abandoned": {
            r = { text: "Abandonado", class: "status status--lost_trading" };
            break;
          }
          case "canceled": {
            r = { text: "Cancelado", class: "status status--objection" };
            break;
          }
          case "trialing": {
            r = { text: "Trial", class: "status status--win" };
            break;
          }
          case "pending_approbation": {
            r = { text: "Liberação manual", class: "is-light-blue" };
            break;
          }

          case "pending_refund":
          case "refund_pending": {
            r = { text: "Reembolso pendente", class: "is-warning" };
            break;
          }
          case "refused": {
            r = { text: "Rejeitado", class: "is-red" };
            break;
          }
          case "chargedback": {
            r = { text: "Estorno", class: "is-warning" };
            break;
          }
          case "delayed": {
            r = { text: "Em processamento", class: "is-warning" };
            break;
          }
          default: {
            r = { text: status, class: "status" };
            break;
          }
        }
        return r;
      } else
        return {
          text: "A descrição do status aparecerá aqui, quando disponível.",
          class: "status",
        };
    },
    handleResize() {
      // this.client.width = window.innerWidth;
      this.client.width = window.innerWidth;
    },
    changedType() {
      // chamando dessa forma para nao
      // passar o event como parametro
      this.fetchLeads();
    },
    debounce: _.debounce(function() {
      let filters = {
        per_page: this.pagination.perPage,
        page: this.pagination.currentPage,
        // ...this.pagination,
        ...this.filters,
      };

      if (this.negociation_status) {
        filters.negociation_status = this.negociation_status;
      }

      if (this.status) {
        filters.status = this.status;
      }

      let search = null;

      if (this.search && this.search !== "") {
        search = this.search;
      }

      this.fetchLeads(search, false, filters);
    }, 500),
    getCoSellerAmount(item) {
      if (item.product.co_sellers) {
        for (let i = 0; i < item.product.co_sellers.length; i++) {
          if (item.product.co_sellers[i].user_id == this.userID) {
            const percentage = item.product.co_sellers[i].percentage;
            return ((item.amount - item.fee) * percentage) / 100;
          }
        }
      }

      return 0;
    },
    openPanel(item) {
      this.sale_id = item.id;
      this.$router.push({ query: { l_id: item.id } }).catch(() => {});

      setTimeout(() => {
        this.$refs.leadPreview.openPanel(item.id, "lead");
        this.loading = false;
      });
    },
    openFilters() {
      this.$bvModal.show("leads-filters");
    },
    exportData() {
      this.fetchLeads(null, true);
    },
    toPage(page) {
      this.pagination.currentPage = page;
      this.debounce();
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;

      this.debounce();
    },
    filter(filters) {
      this.filters = filters;
      this.pagination.currentPage = 1;
      this.fetchLeads();
    },
    fetchLeads(search = null, exportData = false, dados_local = null) {
      this.loading = true;
      // this.pagination.totalPages = 1;

      let data = {};
      if (dados_local) {
        data = dados_local;
      } else {
        data = {
          page: this.pagination.currentPage,
          per_page: this.pagination.perPage,
        };
      }

      if (!exportData) {
        // reset
        this.pagination.totalPages = 1;
        this.leads = [];
        this.total = 0;
        this.itens = 0;
      } else {
        this.initExport = true;
        this.$bvToast.toast("Iniciamos a exportação dos dados, aguarde", {
          variant: "info",
          title: "Exportação",
          autoHideDelay: 8000,
          appendToast: true,
        });
      }

      if (search != null && typeof search == "string" && search.length !== "") {
        data.search = search;
      }

      if (this.dateRange.startDate != null && this.dateRange.endDate != null) {
        data.start_date = moment
          .tz(this.dateRange.startDate, "America/Sao_Paulo")
          .format("YYYY-MM-DDT00:00:00-03:00");
        data.end_date = moment
          .tz(this.dateRange.endDate, "America/Sao_Paulo")
          .format("YYYY-MM-DDT23:59:59-03:00");
      }

      Object.keys(this.filters).forEach(
        (key) =>
          (this.filters[key] == null || this.filters[key].length === 0) &&
          delete this.filters[key]
      );
      data = Object.assign(data, this.filters);

      if (exportData) data.export = true;
      service
        .search(data)
        .then((response) => {
          if (exportData) {
            this.download(response);
          } else {
            this.leads = response.data;
            this.pagination.totalPages = response.last_page;
            this.pagination.perPage = parseInt(response.per_page);
            this.total = response.total;
            this.itens = response.total;
          }

          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    download(data) {
      var file =
        "Cod;Produto;Cpf;Nome;E-mail;Endereco;Telefone;Visto Por Ultimo;Status da Negociacao\n";
      var filename = "Voomp_" + moment().format("DD-MM-YYYY") + ".csv";

      for (let i = 0; i < data.length; i++) {
        let endereco = data[i].street
          ? `${data[i].street} ${data[i].number} ${data[i].neighborhood} ${data[i].city} ${data[i].zipcode} ${data[i].complement}`
          : "Sem endereço";

        file +=
          data[i].id +
          ";" +
          (data[i].proposal ? data[i].proposal.name : data[i].product.name) +
          ";" +
          data[i].cpf +
          ";" +
          data[i].name +
          ";" +
          data[i].email +
          ";" +
          endereco +
          ";" +
          data[i].cellphone +
          ";" +
          moment(data[i].last_seen)
            .locale("pt-br")
            .fromNow() +
          ";" +
          this.getStatus(data[i].negociation_status).text +
          "\n";
      }

      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(file)
      );
      element.setAttribute("download", filename);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);

      this.$bvToast.toast("Dados exportados com sucesso", {
        variant: "success",
        title: "Exportação",
        autoHideDelay: 5000,
        appendToast: true,
      });
      this.initExport = true;
    },
    getMetas(metas) {
      var result = [];
      if (metas.length) {
        metas.forEach((element) => {
          result.push(element.meta_key + "=" + element.meta_value);
        });
      }

      return result.join("&");
    },

    decodeDateFilter(date) {
      switch (date) {
        // hoje
        case "hoje": {
          return moment();
        }
        // ontem
        case "ontem": {
          return moment().subtract(1, "days");
        }
        // uma semana atras
        case "uma semana": {
          return moment().subtract(7, "days");
        }
        // primeiro dia do mes atual
        case "primeiro dia mes atual": {
          return moment().startOf("month");
        }
        // ultimo dia do mes atual
        case "ultimo dia mes atual": {
          return moment().endOf("month");
        }
        // primeiro dia do ANO atual
        case "primeiro dia ano": {
          return moment().startOf("year");
        }
        // ultimo dia do ANO atual
        case "ultimo dia ano": {
          return moment().endOf("year");
        }

        // primeiro dia do mes passado
        case "primeiro dia mes passado": {
          return moment()
            .startOf("month")
            .subtract(1, "month");
        }
        // ultimo dia do mes passado
        case "ultimo dia mes passado":
          return moment()
            .endOf("month")
            .subtract(1, "month")
            .add(1, "days");
        default: {
          return date;
        }
      }
    },

    formatDateFilter(date) {
      switch (date) {
        // hoje
        case moment().format("YYYY-MM-DD"): {
          return "hoje";
        }
        // ontem
        case moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD"): {
          return "ontem";
        }
        // uma semana atras
        case moment()
          .subtract(7, "days")
          .format("YYYY-MM-DD"): {
          return "uma semana";
        }
        // primeiro dia do mes atual
        case moment()
          .startOf("month")
          .format("YYYY-MM-DD"): {
          return "primeiro dia mes atual";
        }
        // ultimo dia do mes atual
        case moment()
          .endOf("month")
          .format("YYYY-MM-DD"): {
          return "ultimo dia mes atual";
        }
        // primeiro dia do ANO atual
        case moment()
          .startOf("year")
          .format("YYYY-MM-DD"): {
          return "primeiro dia ano";
        }
        // ultimo dia do ANO atual
        case moment()
          .endOf("year")
          .format("YYYY-MM-DD"): {
          return "ultimo dia ano";
        }

        // primeiro dia do mes passado
        case moment()
          .startOf("month")
          .subtract(1, "month")
          .format("YYYY-MM-DD"): {
          return "primeiro dia mes passado";
        }
        // ultimo dia do mes passado
        case moment()
          .endOf("month")
          .subtract(1, "month")
          .format("YYYY-MM-DD"):
        case moment()
          .endOf("month")
          .subtract(1, "month")
          .add(1, "days")
          .format("YYYY-MM-DD"): {
          return "ultimo dia mes passado";
        }
        default: {
          return date;
        }
      }
    },
    copyAddress(href) {
      const el = document.createElement("textarea");
      el.value = href;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      this.$bvToast.toast("O link foi copiado com sucesso!", {
        variant: "success",
        title: "Copiado!",
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    updateLead(negociation_status, lead) {
      const leads = this.leads;
      let lead_to_update = lead;
      let new_leads = [];
      leads.map((l) => {
        let new_lead = l;
        if (l.id === lead.id) {
          new_lead.negociation_status = negociation_status;
          lead_to_update.negociation_status = negociation_status;
        }
        new_leads.push(new_lead);
      });

      this.leads = new_leads;

      service
        .update(lead_to_update)
        .then(() => {
          this.$bvToast.toast(
            "O status da negociação foi atualizado com sucesso!",
            {
              variant: "success",
              title: "Atualizado!",
              autoHideDelay: 5000,
              appendToast: true,
            }
          );
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if (this.$store.getters.setPanel) {
      this.$router.push("/dashboard");
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  mounted() {
    this.loading = true;

    this.fetchLeads();

    // abrindo venda unica
    if (typeof this.$route.query.l_id != "undefined") {
      this.sale_id = this.$route.query.l_id;
      setTimeout(() => {
        this.$refs.leadPreview.openPanel(this.sale_id, "lead");
        this.loading = false;
      }, 1000);
    }
  },
};
</script>

<style lang="scss" scoped>
.metas {
  list-style: none;
  font-size: 11px;

  li {
    word-break: break-all;
  }
}
.btn-table {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: rgba(237, 237, 240, 0.5);
  outline: none;
}
.btn-table:active {
  background: rgba(237, 237, 240, 0.5);
  border: none;
}
.btn-table img {
  filter: invert(50%);
}
.btn-table:first-child {
  background: rgba(0, 33, 99, 0.1);
}
.btn-table:first-child:active {
  background: rgba(0, 33, 99, 0.1);
  border: none;
}
.btn-table:first-child img {
  filter: invert(0);
}
.btn-table:nth-child(2) {
  margin-left: 15px;
  margin-right: 20px;
}
.header-table {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
  padding-top: 15px;
}
.flex-sale {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.datas .vue-daterange-picker {
  width: 177px;
  font-size: 14px !important;
  color: #81858e !important;
}
.reset-data {
  font-size: 14px;
  color: #81858e;
  font-weight: normal;
  margin-left: 15px;
}
.input-busca {
  width: 350px;
  height: 60px !important;
  background: #ffffff;
  /* box-shadow: 0px 4px 8px rgba(30, 60, 90, 0.1); */
  border-radius: 5px;
  padding: 20px 30px;
  padding-left: 45px !important;
  border: none;
  font-family: Montserrat;
  font-weight: normal;
  color: #81858e;
  font-size: 14px;
  transition: 0.5s;
}
.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}

.search {
  position: absolute;
  left: 52px;
  top: 22px;
  width: 14.7px;
  height: 14.7px;
  z-index: 999;
}
@media screen and (max-width: 1300px) {
  .search {
    left: 30px;
  }
}
.container-pesquisa {
  text-align: right;
  z-index: 1;
}
@media screen and (max-width: 768px) {
  .header-table {
    display: block;
    padding: 0;
  }
  .flex-sale {
    display: block;
  }
  .flex-sale div {
    margin-left: 0;
  }
  .flex-acoes {
    margin: 15px 0;
  }
  .container-pesquisa {
    text-align: left;
  }
  .search {
    left: 20px;
  }
  .input-busca {
    width: 100%;
  }
}
.dados-cliente div {
  margin-bottom: 3px;
}
.dados-cliente div:nth-child(2) {
  font-weight: normal;
  font-size: 13px;
}

.valor-venda {
  margin-left: 10px;
}

#id_venda {
  position: relative;
}
#id_venda::before {
  content: "";
  width: 15px;
  height: 15px;
  background: transparent;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: -25px;
  top: 0;
}
.TWO_CREDIT_CARDS {
  position: absolute;
  left: 5px;
  top: -5px;
}

// tipo data
#type {
  margin-bottom: 0 !important;
  margin-left: 10px;
}
.Table-body {
  padding: 15px;
}
.Table-body::before {
  left: 0;
}

.fonte {
  font-size: 14px;
  color: #333;
  font-weight: 600;
}
.btn-status {
  height: 40px;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 500;
}
.pointer {
  cursor: pointer;
}
.status {
  width: 6px;
  height: 6px;
  background: #333;
  border-radius: 50%;
  margin-right: 6px;
  transition: all 0.3s ease;
}
.status--opened {
  background: #002363;
}
.status--objection {
  background: #ffcb37;
}
.status--in_negociation {
  background: #2ed7ee;
}
.status--lost_trading {
  background: #ff0c37;
}
.status--win {
  background: #002363;
}
</style>
