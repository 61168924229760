<template>
  <div>
    <slot name="button" :openModal="openModal"> </slot>

    <b-modal
      :id="`refund-solicitation-motives-${id}`"
      size="md"
      title="Motivos da solicitação"
    >
      <b-container>
        <b-row>
          <b-col>
            <b-form-group label="Categoria" label-for="category">
              <b-form-select
                id="category"
                v-model="category"
                :options="categories"
                name="category"
                v-validate="'required'"
              ></b-form-select>
              <b-form-invalid-feedback :state="!errors.has('category')">
                Por favor, indique a categoria.
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="Motivo" label-for="motive">
              <b-form-textarea
                id="motive"
                placeholder="Opcional"
                v-model="motive"
                rows="3"
                max-rows="8"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>

      <template v-slot:modal-footer="{ cancel }">
        <BaseButton variant="secondary_outline" @click="cancel()" class="mr-4">
          Cancelar
        </BaseButton>
        <BaseButton variant="link-info" @click="onSubmit">
          Solicitar
        </BaseButton>
      </template>
    </b-modal>
  </div>
</template>
<script>
import UserService from "@/services/resources/UserService";
import SaleService from "@/services/resources/SaleService";
import RefundService from "@/services/resources/RefundService";

const serviceUser = UserService.build();
const serviceRefund = RefundService.build();

export default {
  data() {
    return {
      id: parseInt(Math.random() * 1000),
      sale_id: -1,
      motive: null,
      category: null,
      categories: [
        { value: null, text: "Categoria" },
        { value: "BUY_WRONG", text: "Comprou errado" },
        { value: "GAVE_UP_BUYING", text: "Desistiu da compra" },
        {
          value: "EXPECTATIONS_NOT_MEETED",
          text: "O produto não atendeu a expectativa",
        },
        {
          value: "PRODUCT_NOT_DELIVERED",
          text: "O vendedor não entregou o produto",
        },
        { value: "DUPLICATE_PURCHASE", text: "Compra duplicada" },
      ],
    };
  },
  methods: {
    openModal(sale_id) {
      this.sale_id = sale_id;
      this.$bvModal.show(`refund-solicitation-motives-${this.id}`);
    },
    onSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let data = {
            sale_id: this.sale_id,
            category: this.category,
            motive: this.motive,
          };

          serviceRefund
            .create(data)
            .then((response) => {
              this.$bvToast.toast("A solicitação foi feita com sucesso!", {
                title: "Reembolso!",
                variant: "success",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.category = null;
              this.motive = null;
              this.$bvModal.hide(`refund-solicitation-motives-${this.id}`);
              this.$emit("close");
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.Affiliate-item {
  padding: 10px 0;
  cursor: pointer;

  &:hover {
    background: #eee;
    color: #111;
  }
}

.active {
  background: $base-color;
  color: #fff;
}
</style>
