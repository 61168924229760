<template>
  <div>
    <slot name="button" :openPanel="openPanel"> </slot>
    <b-sidebar
      id="sidebar-sales"
      width="450px"
      backdrop-variant="dark"
      backdrop
      right
      shadow
      v-model="visible"
      data-anima="sidebar"
      @hidden="resetUrl"
    >
      <b-container fluid class="container-sidebar">
        <b-row class="panel text-left">
          <b-col>
            <b-overlay :show="loading">
              <b-row v-if="openType !== 'lead'" class="item-dados">
                <b-col class="sumary">
                  <b-row
                    class="mb-3"
                    v-if="sale.type === 'SUBSCRIPTION' && id_contract"
                    data-anima="top"
                  >
                    <BaseButton
                      variant="secondary_outline"
                      id="contrato-vinculado"
                      class="btn-entrega button-info3 btn-link-contract"
                      @click="openContract(id_contract)"
                    >
                      <img src="@/assets/img/icons/link-externo2.svg" />
                      Contrato #{{ id_contract }}
                    </BaseButton>
                    <b-tooltip
                      target="contrato-vinculado"
                      title="Contrato vinculado"
                    />
                  </b-row>

                  <b-row class="mb-3">
                    <b-col cols="5">
                      <p class="label-campo">Código</p>
                      <p class="dados-campo">{{ sale.id }}</p>
                    </b-col>
                    <b-col cols="6">
                      <p class="label-campo">Status</p>
                      <template>
                        <p
                          class="dados-campo"
                          :class="getStatus(sale.status).class"
                        >
                          {{ getStatus(sale.status).text }}
                        </p>
                      </template>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="5 mb-3"
                      v-if="sale.status == 'paid' || sale.status == 'trialing'"
                    >
                      <p class="label-campo">Pagamento</p>
                      <p class="dados-campo">{{ sale.paid_at | date }}</p>
                    </b-col>
                    <b-col
                      cols="5"
                      v-if="sale.status == 'paid' || sale.status == 'trialing'"
                    >
                      <p class="label-campo">Criação</p>
                      <p class="dados-campo">{{ sale.created_at | date }}</p>
                    </b-col>
                  </b-row>
                  <b-row class="mb-2" v-if="sale.status == 'refused'">
                    <b-col cols="12">
                      <p class="label-campo">Motivo da Recusa</p>
                      <p
                        class="dados-campo"
                        :class="getStatus(sale.status).class"
                        v-if="sale.error_code_reason"
                      >
                        {{ checkError(sale.error_code_reason) }}
                      </p>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="5 mb-3">
                      <p class="label-campo">Valor bruto</p>
                      <p
                        class="dados-campo"
                        v-if="sale.total && sale.installments > 1"
                      >
                        R$ {{ formatMoney(+sale.total) }}
                        <span v-if="sale.installments > 1"
                          >em {{ sale.installments }}x</span
                        >
                      </p>
                      <p class="dados-campo" v-else-if="sale.amount">
                        R$ {{ formatMoney(+sale.amount) }}
                        <span v-if="sale.installments > 1"
                          >em {{ sale.installments }}x</span
                        >
                      </p>
                      <p v-else class="dados-campo">R$ 0,00</p>
                    </b-col>

                    <b-col cols="5">
                      <p class="label-campo">Valor recebido</p>
                      <p class="dados-campo" v-if="sale.balance">
                        R$ {{ formatMoney(+sale.balance) }}
                      </p>
                      <p class="dados-campo" v-else>R$ 0,00</p>
                    </b-col>
                    <b-col cols="5">
                      <p class="label-campo">Método</p>
                      <p class="dados-campo">
                        {{ getMethod(sale.method) }}
                      </p>
                    </b-col>
                  </b-row>
                  <b-row
                    class="mt-2"
                    v-if="
                      sale.method == 'CREDIT_CARD' ||
                        sale.method == 'TWO_CREDIT_CARDS'
                    "
                  >
                    <b-col cols="7">
                      <p class="label-campo">Cartão utilizado</p>
                      <p class="dados-campo" v-if="sale.card_last_digits">
                        #### #### #### {{ sale.card_last_digits }}
                      </p>
                      <p class="dados-campo" v-else>---</p>
                    </b-col>

                    <b-col cols="12" class="mt-2">
                      <p class="dados-campo mt-2">
                        <b-badge
                          variant="secondary"
                          v-if="sale.language == 'pt' || !sale.language"
                        >
                          🇧🇷 Venda nacional
                        </b-badge>
                        <b-badge
                          variant="secondary"
                          v-if="sale.language && sale.language != 'pt'"
                        >
                          🌎 Venda internacional
                        </b-badge>
                      </p>
                    </b-col>

                    <b-col
                      cols="12"
                      class="mt-2"
                      v-if="
                        sale.product &&
                          sale.product.installments > 1 &&
                          sale.product.no_interest_installments != null
                      "
                    >
                      <p class="label-campo">Juros</p>
                      <p class="dados-campo mt-2">
                        <b-badge variant="primary"
                          >{{
                            sale.product.no_interest_installments
                              ? "Vendedor "
                              : "Cliente "
                          }}assumiu o Juros</b-badge
                        >
                      </p>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <!-- Product -->
              <div class="item-dados">
                <b-col>
                  <h5 class="titulo-item">Produto</h5>

                  <b-col cols="12">
                    <div class="product-main">
                      <div class="img-product">
                        <img
                          v-if="
                            !loading &&
                              sale &&
                              sale.product &&
                              sale.product.images &&
                              sale.product.images.length
                          "
                          :src="sale.product.images[0].path"
                        />
                      </div>
                      <div class="name-product" v-if="!loading && sale.product">
                        <span>Produto: #{{ sale.product.id }}</span>
                        <template v-if="!sale.proposal">
                          <p v-if="sale.product">{{ sale.product.name }}</p>
                          <p v-else>{{ sale.invoices[0].product.name }}</p>
                          <p class="hash" v-if="sale.offer">
                            Código da Oferta: {{ sale.offer.hash }}
                          </p>
                          <p
                            class="hash"
                            v-if="
                              sale.invoices &&
                                sale.invoices[0] &&
                                sale.invoices[0].offer.hash
                            "
                          >
                            Código da Oferta: {{ sale.invoices[0].offer.hash }}
                          </p>
                        </template>
                        <template v-else>
                          <p>
                            {{ sale.proposal.name }}
                          </p>
                          <p class="hash" v-if="sale.offer">
                            Código da Oferta: {{ sale.offer.hash }}
                          </p>
                        </template>
                      </div>
                    </div>
                  </b-col>
                </b-col>
              </div>

              <!-- Avaliação -->
              <div class="item-dados" v-if="sale.product_rating">
                <b-col>
                  <h5 class="titulo-item">Avaliação</h5>

                  <div class="avaliacao-item">
                    <b-form-rating
                      id="rating"
                      v-model="sale.product_rating"
                      variant="warning"
                      class="mb-2"
                      readonly
                      show-value
                      show-value-max
                      size="lg"
                    ></b-form-rating>
                  </div>
                  <b-col
                    class="product-avaliacao text-avaliacao"
                    v-if="sale.avaliation.length"
                  >
                    <img src="@/assets/img/icons/info-prod.svg" />
                    {{ sale.avaliation[0].avaliation_value }}
                  </b-col>
                </b-col>
              </div>

              <!-- Cupom -->
              <b-row class="item-dados" v-if="sale.coupon_id">
                <b-col>
                  <h5 class="titulo-item">Cupom Aplicado</h5>
                  <p class="label-campo">
                    <span class="title-item">Código do Cupom</span>
                    <span>{{ sale.coupon.name }}</span>
                  </p>

                  <p class="label-campo">
                    <span class="title-item">Desconto</span>
                    <span v-if="sale.coupon.type == 'AMOUNT'"
                      >R$ {{ formatMoney(sale.coupon.amount) }}</span
                    >
                    <span v-if="sale.coupon.type == 'PERCENTAGE'"
                      >{{ sale.coupon.amount }}%</span
                    >
                  </p>
                </b-col>
              </b-row>

              <!-- Affiliate -->
              <b-row v-if="sale.affiliate" class="item-dados">
                <b-col>
                  <h5 class="titulo-item">Afiliado</h5>
                  <p class="label-campo">
                    <span class="title-item">Nome</span>
                    <span>{{ sale.affiliate.name }}</span>
                  </p>
                  <p class="label-campo">
                    <span class="title-item">Endereço de e-mail</span>
                    <span>{{ sale.affiliate.email }}</span>
                  </p>
                </b-col>
              </b-row>

              <!-- Client -->
              <b-row class="item-dados" v-if="sale.client">
                <b-col>
                  <h5
                    class="titulo-item"
                    v-if="sale.client && sale.client.name"
                  >
                    Cliente
                  </h5>
                  <p class="label-campo" v-if="sale.client.name">
                    <span class="title-item">Nome</span>
                    <span>{{ sale.client.name }}</span>
                  </p>
                  <p class="label-campo" v-if="sale.client.email">
                    <span class="title-item">Endereço de e-mail</span>
                    <span>{{ sale.client.email }}</span>
                  </p>
                  <p
                    class="label-campo"
                    v-if="sale.client.cellphone || sale.cellphone"
                  >
                    <span class="title-item">Telefone</span>
                    <a
                      :href="
                        formatTel(
                          sale.client ? sale.client.cellphone : sale.cellphone
                        )
                      "
                      target="_blank"
                      class="whats-cliente"
                      >{{ sale.client.cellphone }}
                      <font-awesome-icon :icon="['fab', 'whatsapp']"
                    /></a>
                  </p>
                  <p
                    class="label-campo"
                    v-if="sale.client.cpf_cnpj || sale.client.cpf"
                  >
                    <span class="title-item">CPF/CNPJ</span>
                    <span>{{
                      openType != "lead"
                        ? sale.client.cpf_cnpj
                        : sale.client.cpf
                    }}</span>
                  </p>
                  <div v-if="sale.client && sale.client.zipcode" class="mt-2">
                    <p class="label-campo">
                      <span class="title-item">CEP</span>
                      <span>{{ sale.client.zipcode }}</span>
                    </p>
                    <p class="label-campo">
                      <span class="title-item">Logradouro</span>
                      <span>{{ sale.client.street }}</span>
                    </p>
                    <p class="label-campo">
                      <span class="title-item">Número</span>
                      <span>{{ sale.client.number }}</span>
                    </p>
                    <p class="label-campo" v-if="sale.client.complement">
                      <span class="title-item">Complemento</span>
                      <span>{{ sale.client.complement }}</span>
                    </p>
                    <p class="label-campo">
                      <span class="title-item">Bairro</span>
                      <span>{{ sale.client.neighborhood }}</span>
                    </p>
                    <p class="label-campo">
                      <span class="title-item">Cidade</span>
                      <span>{{ sale.client.city }}</span>
                    </p>
                    <p class="label-campo">
                      <span class="title-item">Estado</span>
                      <span>{{ sale.client.uf }}</span>
                    </p>
                  </div>
                </b-col>
              </b-row>

              <b-row class="item-dados" v-if="sale.shipping_amount">
                <b-col>
                  <h5 class="titulo-item">Frete escolhido</h5>

                  <div class="frete-item" v-if="sale.shipping_selected">
                    <div class="infos-frete">
                      <img :src="sale.shipping_selected.company.picture" />
                      <div class="info-envio">
                        <span>
                          {{ sale.shipping_selected.name }}
                        </span>
                        <span v-if="sale.shipping_selected.delivery_range">
                          {{ sale.shipping_selected.delivery_range.min }} à
                          {{ sale.shipping_selected.delivery_range.max }}
                          dias úteis
                        </span>
                        <span class="frete-valor">
                          R$
                          {{ formatMoney(+sale.shipping_selected.price) }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="frete-item" v-else>FRETE GRÁTIS</div>
                </b-col>
              </b-row>

              <!-- Nota Fiscal -->
              <b-row class="item-dados" v-if="openType != 'lead'">
                <b-col>
                  <h5 class="titulo-item">Nota Fiscal</h5>
                  <p class="label-campo">
                    <span class="title-item">Emissão da nota</span>
                    <span class="nota-status emitida" v-if="sale.note_issue_by"
                      ><img src="@/assets/img/icons/mini-check.svg" />
                      Nota emitida
                    </span>
                    <span class="nota-status emitida" v-if="!sale.note_issue_by"
                      ><img src="@/assets/img/icons/mini-x.svg" />
                      Nota não emitida
                    </span>
                  </p>

                  <p class="label-campo" v-if="sale.note_issue_by">
                    <span class="title-item">Emitida por</span>
                    <span>{{ sale.note_issue_by }}</span>
                  </p>
                </b-col>
              </b-row>

              <!-- Participações -->
              <b-row
                class="item-dados"
                v-if="openType != 'lead' && distribuitions.length"
              >
                <h5
                  class="titulo-item titulo-arrow"
                  v-b-toggle.collapse-2
                  @click="collapse = !collapse"
                  :class="{ ativo: collapse }"
                >
                  Participações
                  <img src="@/assets/img/icons/arrow-fill.svg" alt="" />
                </h5>

                <b-collapse id="collapse-2" data-anima="top">
                  <b-row class="mt-3 mb-1">
                    <div class="label-campo"></div>
                  </b-row>
                  <b-row class="mt-3 mb-1">
                    <div class="grid-participations">
                      <span class="subtitle-item">Total Bruto</span>
                      <span class="desctitle-item" style="color: #2A63AB;"
                        >R$ {{ formatMoney(sale.amount) }}</span
                      >
                    </div>
                  </b-row>
                  <b-row
                    v-for="distribuition in distribuitions"
                    :key="distribuition"
                  >
                    <div class="grid-participations">
                      <span class="subtitle-item"
                        >{{
                          distribuition.type
                            ? getParticipations(distribuition.type)
                            : getParticipations(distribuition.role)
                        }}
                        {{
                          distribuition.type ? "" : `"${distribuition.name}"`
                        }}</span
                      >
                      <span
                        class="desctitle-item"
                        style="color: rgb(255, 12, 55)"
                        >- R$
                        {{
                          distribuition.value
                            ? formatMoney(distribuition.value)
                            : formatMoney(distribuition.balance)
                        }}</span
                      >
                    </div>
                  </b-row>
                  <b-row class="mt-3 mb-1">
                    <div class="grid-participations">
                      <span class="subtitle-item">Total Líquido:</span>
                      <span class="desctitle-item" style="color: #002363"
                        >R$ {{ formatMoney(sale.balance) }}</span
                      >
                    </div>
                  </b-row>
                </b-collapse>
              </b-row>

              <!-- Boleto -->
              <b-row
                v-if="
                  sale.method == 'BOLETO' &&
                    !sale.affiliate &&
                    openType != 'lead'
                "
                class="item-dados"
              >
                <b-col>
                  <h5 class="titulo-item">Boleto</h5>
                  <p class="label-campo" v-if="sale.boleto_url || sale.order.boleto_url">Link</p>
                  <p class="dados-campo">
                    <a
                      :href="sale.boleto_url || sale.order.boleto_url"
                      target="_blank"
                      class="dados-campo"
                      >{{ sale.boleto_url || sale.order.boleto_url }}</a
                    >
                  </p>

                  <p class="label-campo mt-2" v-if="isSeller">
                    Código de barras
                  </p>
                  <p class="dados-campo">
                    {{
                      sale.boleto_barcode
                        ? sale.boleto_barcode
                        : sale.order.boleto_barcode
                    }}

                    <font-awesome-icon
                      icon="copy"
                      style="cursor: pointer; margin-left: 5px"
                      @click="copy"
                    />
                  </p>
                </b-col>
              </b-row>

              <!-- Options -->
              <b-row class="item-dados center" v-if="!$store.getters.setPanel">
                <b-col>
                  <div class="btn-flex" v-if="openType != 'lead'">
                    <Refund
                      v-if="
                        sale.status == 'refund_pending' ||
                          sale.status == 'paid' ||
                          (sale.status == 'canceled' && !sale.affiliate)
                      "
                      @close="fetchSale"
                    >
                      <template v-slot:button="{ openModal }">
                        <BaseButton
                          variant="accent_outline"
                          @click="openModal(sale_id)"
                          :disabled="sale.status == 'refund_pending'"
                        >
                          {{
                            sale.status == "refund_pending"
                              ? "Processando Reembolso"
                              : "Realizar Reembolso"
                          }}
                        </BaseButton>
                      </template>
                    </Refund>

                    <Affiliation
                      v-if="
                        sale.seller_id == userID &&
                          !sale.affiliate &&
                          sale.status == 'paid'
                      "
                      :product_id="sale.product.id"
                      from="sale"
                      @close="fetchSale"
                    >
                      <template v-slot:button="{ openModal }">
                        <BaseButton
                          variant="secondary_outline"
                          @click="openModal(sale_id)"
                        >
                          Atribuir Afiliado
                        </BaseButton>
                      </template>
                    </Affiliation>
                    <BaseConfirmDialog
                      v-if="
                        sale.method == 'BOLETO' &&
                          sale.status == 'waiting_payment' &&
                          !sale.affiliate
                      "
                      @confirmed="updateSale"
                      class="ml-2 d-inline-block"
                    >
                      <template v-slot:button="{ openModal }">
                        <BaseButton variant="outline-info" @click="openModal"
                          >Liberar Manualmente</BaseButton
                        >
                      </template>
                    </BaseConfirmDialog>

                    <!-- <BaseButton variant="black" disabled>
                      Refazer Entregas
                    </BaseButton> -->
                  </div>
                  <div
                    v-if="openType === 'lead' && sale.cellphone"
                    class="btn-flex"
                  >
                    <BaseButton
                      class="mt-3"
                      variant="primary"
                      @click="openLink(formatTel(sale.cellphone))"
                    >
                      Chamar no WhatsApp
                    </BaseButton>
                  </div>
                </b-col>
              </b-row>
              <b-row v-if="openType !== 'lead'" class="item-dados">
                <b-col>
                  <h5 class="titulo-item">Metas Adicionais</h5>
                  <div v-if="sale && sale.metas && sale.metas.length">
                    <div
                      v-for="meta in sale.metas"
                      :key="meta.meta_key"
                      class="meta-item"
                    >
                      <p class="key-meta">• {{ meta.meta_key }}</p>
                      <p class="key-value">{{ meta.meta_value }}</p>
                    </div>
                  </div>
                  <div v-else>
                    <p class="sem-historico">
                      Sem metas adicionais cadastradas
                    </p>
                  </div>
                </b-col>
              </b-row>
            </b-overlay>
          </b-col>
        </b-row>
      </b-container>
    </b-sidebar>
  </div>
</template>
<script>
import SaleService from "@/services/resources/SaleService";
import LeadService from "@/services/resources/LeadService";
import Money from "@/mixins/money";
import Refund from "./modals/RefundSolicitationMotives";
import Affiliation from "./modals/Affiliation";
import ContractService from "@/services/resources/ContractService";
import errorPayment from "@/utils/errorPayment.js";

import { textMethod } from "@/helpers.js";

const serviceSale = SaleService.build();
const serviceLead = LeadService.build();

const serviceContract = ContractService.build();

export default {
  name: "DetalhesVenda",
  components: {
    Refund,
    Affiliation,
  },
  mixins: [Money],
  data() {
    return {
      distribuitions: [],
      loading: true,
      visible: false,
      collapse: false,
      id_contract: "",
      sale_id: -1,
      sale: {
        avaliation: [],

        product: {
          name: "",
          description: "",
          images: [{ path: "" }],
        },
        client: {
          name: "",
          email: "",
          cellphone: "",
        },
        proposal: {
          name: "",
          description: "",
        },
        refund_solicitations: [],
      },
      openType: null,
    };
  },
  computed: {
    isSeller() {
      return this.$store.getters.isSeller;
    },
    userID() {
      return this.$store.getters.getProfile.id;
    },
    refundRequested() {
      if (this.sale.refund_solicitations != null) {
        for (var i = 0; i < this.sale.refund_solicitations.length; i++)
          if (
            this.sale.refund_solicitations[i].status == "REQUESTED" &&
            this.sale.status != "refunded"
          )
            return true;
      }

      return false;
    },
  },
  methods: {
    checkError(error) {
      return errorPayment(error);
    },
    getParticipations(status) {
      let r = "";
      switch (status) {
        case "AFFILIATE_FEE": {
          r = "Taxa de Afiliado";
          break;
        }
        case "ANTECIPATION_FEE": {
          r = "Taxa de Antecipação";
          break;
        }
        case "SELLER_FEE": {
          r = "Taxa de Vendedor";
          break;
        }
        case "SELLER": {
          r = "Vendedor";
          break;
        }
        case "MARKETPLACE_FEE": {
          r = "Taxa de Mercado";
          break;
        }
        case "USER_ANTECIPATION_FEE": {
          r = "Taxa de Antecipação do Usuário";
          break;
        }
        case "SELLER_FEE_INTERNATIONAL": {
          r = "Taxa de Vendedor Internacional";
          break;
        }
        case "SELLER_INTEREST_FEE": {
          r = "Taxa de Juros de Vendedor";
          break;
        }
        case "CO_SELLER": {
          r = "Co-Produtor";
          break;
        }
        case "AFFILIATE": {
          r = "Afiliado";
          break;
        }
        default: {
          r = status;
          break;
        }
      }
      return r;
    },
    getMethod(method) {
      return textMethod(method);
    },
    openContract(id_contract) {
      this.$router.push("/contratos?c_id=" + id_contract);
    },
    formatTel(tel) {
      if (tel) {
        if (tel.toString().indexOf("+55") > -1) {
          var tell = tel.replace(/[() -]/g, "");
          if (tell[0] === "+") {
            tell = tell.substring(1);
          }
          return `https://api.whatsapp.com/send?phone=${tell}`;
        } else {
          return `https://api.whatsapp.com/send?phone=55${tel.replace(
            /[() -]/g,
            ""
          )}`;
        }
      }
    },
    getStatus(status) {
      let r = {};
      if (status) {
        switch (status.toLowerCase()) {
          case "processing": {
            r = { text: "Processando", class: "icon-blue" };
            break;
          }
          case "authorized": {
            r = { text: "Transação autorizada", class: "icon-blue" };
            break;
          }
          case "pending_approbation": {
            r = { text: "Liberação manual", class: "icon-blue" };
            break;
          }
          case "paid": {
            r = {
              text: "Pago",
              class: "icon-green",
            };
            break;
          }
          case "refunded": {
            r = { text: "Estornado", class: "icon-red" };
            break;
          }

          case "waiting_payment": {
            r = { text: "Aguardando pagamento", class: "icon-warning" };
            break;
          }
          case "pending_refund":
          case "refund_pending": {
            r = { text: "Reembolso pendente", class: "icon-warning" };
            break;
          }
          case "refused": {
            r = { text: "Rejeitado", class: "icon-red" };
            break;
          }
          case "chargedback": {
            r = { text: "Estorno", class: "icon-warning" };
            break;
          }
          case "delayed": {
            r = { text: "Em processamento", class: "icon-warning" };
            break;
          }
          default: {
            r = { text: status, class: "icon-black" };
            break;
          }
        }
        return r;
      } else return { text: status, class: "icon-black" };
    },
    resetUrl() {
      if (Object.values(this.$route.query).length) {
        this.$router.push({ query: {} });
      }
    },
    openPanel(sale_id, openType) {
      this.openType = openType ? openType : null;
      this.visible = true;
      this.sale_id = sale_id;
      openType === "lead" ? this.fetchLead() : this.fetchSale();
    },
    copy() {
      const el = document.createElement("textarea");
      el.value = this.sale.boleto_barcode
        ? this.sale.boleto_barcode
        : this.sale.order.boleto_barcode;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      this.$bvToast.toast("O código de barras foi copiado!", {
        variant: "success",
        title: "Copiado!",
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    getCoSellerPercentage() {
      if (this.sale.product.co_sellers) {
        for (let i = 0; i < this.sale.product.co_sellers.length; i++) {
          if (this.sale.product.co_sellers[i].user_id == this.userID) {
            return this.sale.product.co_sellers[i].percentage;
          }
        }
      }

      return 0;
    },
    getCoSellerAmount() {
      if (this.sale.product.co_sellers) {
        for (let i = 0; i < this.sale.product.co_sellers.length; i++) {
          if (this.sale.product.co_sellers[i].user_id == this.userID) {
            const percentage = this.sale.product.co_sellers[i].percentage;
            return ((this.sale.amount - this.sale.fee) * percentage) / 100;
          }
        }
      }

      return 0;
    },
    fetchContract() {
      let data = {
        id:
          "0?contract_id=" +
          this.sale.contract_id +
          "&client_id=" +
          this.sale.client_id,
      };

      serviceContract
        .read(data)
        .then((response) => {
          this.id_contract = response.id || null;
        })
        .catch((err) => {
          console.log(err);
          this.id_contract = null;
        });
    },
    fetchSale() {
      this.sale = {
        product: {
          name: "",
          description: "",
          images: [{ path: "" }],
        },
        client: {
          name: "",
          email: "",
          cellphone: "",
        },
        proposal: {
          name: "",
          description: "",
        },
      };

      this.loading = true;

      let data = {
        id: this.sale_id,
      };

      serviceSale
        .read(data)
        .then((response) => {
          this.sale = response;
          this.distribuitions = response.distribuition
            ? response.distribuition
            : [];

          if (this.sale.type === "SUBSCRIPTION") {
            this.fetchContract();
          }

          if (this.sale.shipping_selected) {
            this.sale.shipping_selected = JSON.parse(
              this.sale.shipping_selected
            ).frete;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchLead() {
      this.sale = {
        product: {
          name: "",
          description: "",
          images: [{ path: "" }],
        },
        client: {
          name: "",
          email: "",
          cellphone: "",
        },
        proposal: {
          name: "",
          description: "",
        },
      };

      this.loading = true;

      let data = {
        id: this.sale_id,
      };

      serviceLead
        .read(data)
        .then((response) => {
          console.log(response);
          this.sale = response;
          this.sale.client = response;
          if (this.sale.type === "SUBSCRIPTION") {
            this.fetchContract();
          } else {
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateSale() {
      let data = {
        id: this.sale_id,
        status: "pending_approbation",
      };

      serviceSale
        .update(data)
        .then((response) => {
          if (response.success) {
            this.fetchSale();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openLink(link) {
      window.open(link, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.panel {
  .sumary {
    font-size: 13px;
    // padding-top: 20px;
    // padding-bottom: 20px;
  }

  .product-name {
    font-size: 16px;
    font-weight: 600;
    display: block;
  }
  .product-name span {
    display: block;
    margin: 10px 0 !important;
  }

  .product-image {
    height: 170px;
    overflow: hidden;
    border-radius: 5px;
    background: #f5f2fc;
    display: flex;
    align-items: center;
  }
  .thumb-fake {
    width: 100%;
    color: silver;
    font-size: 32px;
    letter-spacing: 2px;
    justify-content: center;
  }

  .product-description {
    display: block;
    font-size: 14px;
    line-height: 1.5;
    color: #81858e;
    font-weight: normal;
  }
}
.center {
  text-align: center;
}
.container-sidebar {
  padding: 40px;
  padding-top: 20px;
}
.detalhe-item {
  display: flex;
  align-items: center;
  font-weight: 600;
}
.detalhe-item + .detalhe-item {
  margin-top: 25px;
}
.detalhe-item:first-child img {
  margin-right: 23px;
}
.detalhe-item:nth-child(2) img {
  margin-right: 28px;
}
.detalhe-item:nth-child(3) img {
  margin-right: 20px;
}
header.b-sidebar-header {
  padding: 30px !important;
  padding-bottom: 0 !important;
}
.dados-campo {
  font-weight: normal;
  font-size: 13px;
  margin-top: 3px;
}
.item-dados {
  // border: 1px solid ;
  padding: 22px 0;
}
.item-dados + .item-dados {
  border-top: 0.5px solid #ededf0;
}
.titulo-item {
  font-size: 18px;
  font-weight: 600;
  color: $black;
  margin-bottom: 15px;
}
.label-campo {
  font-size: 14px;
  font-weight: 600;
  color: #333;
}
.title-item {
  font-size: 11px;
  color: #81858e;
  display: block;
  font-weight: normal !important;
  margin-bottom: 3px;
}

.subtitle-item {
  font-size: 14px;
  color: #81858e;
  display: block;
  font-weight: normal !important;
  margin-bottom: 3px;
}

.desctitle-item {
  font-size: 14px;
  color: #333;
  display: block;
  font-weight: normal !important;
  margin-bottom: 3px;
}

.label-campo span {
  font-weight: 600;
}
.label-campo + .label-campo {
  margin-top: 5px;
}

.btn-flex {
  display: flex;
  flex-direction: column;
}
.btn-flex div {
  margin-bottom: 13px;
}
.btn-flex div button,
.btn-flex button {
  width: 70%;
  height: 55px;
}
.whats-cliente {
  font-size: 14px;
  color: #2a63ab;
}
.whats-cliente svg {
  margin-left: 5px;
}

// metas

.meta-item + .meta-item {
  margin-top: 10px;
  border-top: 1px solid #ededf0;
  padding: 10px 0;
}

.meta-item .key-meta {
  font-weight: 600;
  margin-bottom: 5px;
}
.meta-item .key-value {
  font-weight: 500;
  margin-left: 10px;
}
.sem-historico {
  margin-top: 20px;
  font-size: 14px;
  color: #81858e;
}

.decoration-none {
  text-decoration: none;
}

.btn-link-contract {
  display: flex;
  align-items: center;
  color: #2a63ab;
  background: rgba(33, 51, 210, 0.1);
  font-size: 12px;
  font-weight: 600;
  padding: 10px 20px !important;
  border-radius: 5px;
  height: 45px;
}

.btn-link-contract img {
  margin-right: 10px;
}
.product-avaliacao {
  display: flex;
  align-items: center;
  font-weight: 600;
  gap: 7px;
  font-size: 14px;
}

.product-avaliacao + .product-avaliacao {
  margin-top: 25px;
}
.product-avaliacao.text-avaliacao {
  font-weight: normal;
  align-items: flex-start;
}

.product-main {
  display: grid;
  grid-template-columns: 0.5fr 3fr;
  gap: 20px;
  align-items: center;
}
.img-product {
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-product,
.img-product img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #ededf0;
  object-fit: cover;
}
.name-product span {
  font-size: 11px;
  color: #81858e;
}
.name-product .hash {
  font-size: 12px;
  color: #81858e;
  font-weight: inherit;
}
.name-product p {
  font-weight: 600;
}
.nota-status {
  display: flex;
  align-items: center;
  gap: 5px;
}
.badge-primary,
.badge-secondary {
  padding: 5px 8px;
  font-weight: 600;
  background-color: rgba(255, 185, 55, 0.2);
  color: #ffb937;
  white-space: pre-line;
  line-height: 1.5;
  text-align: left;
  font-size: 12px;
}
.badge-secondary {
  background: #ededf0;
  color: #333;
  padding: 10px;

  font-size: 13px;
}

// frete

.frete-item img {
  max-width: 80px;
  margin-bottom: 15px;
}

.frete-item .infos-frete {
  display: block;
  font-size: 15px;
  margin-bottom: 7px;
}

.frete-item .info-envio,
.frete-item .info-envio span {
  display: block;
  margin-bottom: 7px;
}

.info-envio .frete-valor {
  font-weight: 600;
}

@media screen and (max-width: 769px) {
  .frete-item .infos-frete {
    width: 100%;
  }

  .frete-item .info-envio {
    grid-template-columns: 1fr;
  }

  .info-envio span {
    font-size: 13px;
  }

  .info-envio span + span {
    margin-top: 3px;
  }
}

// collapse
.titulo-arrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  width: 100%;
}
.titulo-arrow.ativo {
  margin-bottom: 15px;
}
.titulo-arrow.ativo img {
  transition: 0.3s;
  transform: rotate(180deg);
}

.collapse.show {
  width: 100%;
}

.grid-participations {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.grid-participations + .grid-participations {
  margin-top: 7px;
}
</style>
